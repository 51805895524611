import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import PageNotFound from '../../common/PageNotFound';
import Home from './home';
import Login from './login';
import AboutUs from './about-us';
import Wods from './wods';
import SingleWod from './wods/single-wod';
import News from './news';
import SingleNews from './news/single-news';
import WhatIsCrossfit from './what-is-crossfit';
import Terms from './terms';
// import Kids from './kids';
import ResetPassword from './reset-password';
import ActivateUser from './activate-user';

class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/wods" component={Wods} />
        <Route exact path="/wods/:wodId" component={SingleWod} />
        <Route exact path="/news" component={News} />
        <Route exact path="/news/:newsItemId" component={SingleNews} />
        <Route exact path="/what-is-crossfit" component={WhatIsCrossfit} />
        <Route exact path="/terms" component={Terms} />
        {/* <Route exact path="/kids" component={Kids} /> */}
        <Route exact path="/reset-password/:code" component={ResetPassword} />
        <Route exact path="/activate-user/:code" component={ActivateUser} />
        <Route component={PageNotFound} />
      </Switch>
    );
  }
}

export default App;
