import React from 'react';
import { Helmet } from 'react-helmet';
import Iframe from 'react-iframe';

import Wrapper from '../common/Wrapper';
import Heading from '../common/Heading';
import strength from '../../../img/icon-strength.svg';

const WhatIsCrossfit = () => (
  <Wrapper headerSecondary>
    <Helmet>
      <title>Какво е CrossFit?</title>
      <meta
        name="description"
        content="Кросфит е основната силова и кондиционна програма за много полицаи,военни,шампиони по бойни изкуства,както и стотици други елитни и професионални спортисти от цял свят."
      />
    </Helmet>
    <div className="o-wrapper o-wrapper--large u-margin-bottom-huge">
      <Heading
        headingSecondary
        type="headingSecondary"
        src={strength}
        title="Какво е CrossFit?"
      />
      <p>
        Кросфит е основната силова и кондиционна програма за много полицаи,
        военни, шампиони по бойни изкуства, както и стотици други елитни и
        професионални спортисти от цял свят.
      </p>
      <p>
        Нашата програма предлага фитнес, който е основен, широкоспектърен и
        приобщаващ. Не специализираме (не се съсредоточаваме върху едно
        упражнение или мускулна група, а използваме най-различни вариации и
        упражнения). Кросфит намира приложение в армията, полицията и много
        спортове, също така помага в ежедневието, където все по-често, особено с
        напредване на възръстта, срещаме трудности при наглед елементарни
        физически натоварвания.
      </p>
      <p>
        Програмата Кросфит е универсално приложима, което я прави перфектна за
        всички, независимо от опита, който имат. Ние прилагаме същите наши
        упражнения както за хора в напреднала възраст със сърдечно-съдови
        заболявания, така и за бойци на ринга например. Ние скалираме тежестта и
        интензитета, не променяме програмите!
      </p>

      <p>
        Кросфит е фитнес режим, изработен от треньора Грег Гласман в течение на
        няколко десетилетия. Tой е и първият, който определя термина “фитнес” по
        един съдържателен начин като “повишена работоспособност в широк спектър
        от модалности и време”. Кросфит се дефинира като програма, която
        оптимизира фитнеса (постоянно променящи се функционални
        движения,изпълнени с висока интензивност). Кросфит е общността, която
        възниква спонтанно, когато хората правят тези тренировки заедно...
        Всъщност, груповият аспект е ключовият компонент, който прави кросфит
        толкова ефективен.
      </p>
      <Iframe
        url="https://www.youtube.com/embed/mlVrkiCoKkg"
        width="100%"
        height="500"
        position="relative"
        className="u-margin-top-large"
      />
    </div>
  </Wrapper>
);

export default WhatIsCrossfit;
