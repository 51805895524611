import React from 'react';
import ReactSVG from 'react-svg';
import Iframe from 'react-iframe';

import Heading from '../common/Heading';
import gym from '../../../img/icon-gym.svg';
import facebook from '../../../img/icon-facebook.svg';
import instagram from '../../../img/icon-instagram.svg';
import youtube from '../../../img/icon-youtube.svg';

const ContactUs = () => (
  <div className="o-wrapper o-wrapper--large">
    <div className="o-layout u-padding-vertical-huge">
      <Heading
        subtitle="контакти"
        title="свържете се с нас"
        src={gym}
        className="u-margin-bottom-huge u-padding-left"
      />
      <div className="u-flex u-align-items-center u-text-center u-flex-wrap">
        <div className="o-layout__item u-1/1 u-1/2@md">
          <p className="u-weight-bold u-margin-bottom-small">CrossFit Varna</p>
          <p>
            местност Траката, ул. “4-та” 4 гр. <br />
            Варна, България
          </p>
          <p className="u-margin-bottom-tiny u-margin-top-large">
            info@crossfitvarna.com
          </p>
          <p>+359 88 684 94 69</p>
          <section className="u-margin-vertical-large u-flex u-justify-content-center">
            <a
              href="https://www.facebook.com/CrossFitVarna/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ReactSVG
                alt="Facebook Logo"
                src={facebook}
                className="u-img-outlined u-img-round"
              />
            </a>
            <a
              href="https://www.instagram.com/crossfitvarna/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ReactSVG
                alt="Instagram Logo"
                src={instagram}
                className="u-img-outlined u-img-round"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCMxdZc-TSBTq_P9eFINYt8A"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ReactSVG
                alt="Youtube Logo"
                src={youtube}
                className="u-img-outlined u-img-round"
              />
            </a>
          </section>
        </div>
        <div className="o-layout__item u-1/1 u-1/2@md u-flex u-justify-content-center">
          <Iframe
            url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5814.692644852261!2d27.962534629543356!3d43.22319808457365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a4538ca79093c9%3A0xcab287b60d939d3d!2sCrossfit+Varna!5e0!3m2!1sen!2sbg!4v1548518899033"
            width="500"
            height="350"
            position="relative"
          />
        </div>
      </div>
    </div>
  </div>
);

export default ContactUs;
