import React, { Component } from 'react';

import api from '../../../lib/api';
import Loading from '../../../common/Loading';
import Error from '../../../common/Error';
import Button from '../../../common/Button';
import Picture from '../../../common/Picture';
import Heading from '../common/Heading';
import kettlebell from '../../../img/icon-kettlebell.svg';
import noImg from '../../../img/landing/no-img.jpg';
import noImgWebp from '../../../img/landing/webp/no-img.webp';
import WodModel from '../../../models/Wod';

class Wod extends Component {
  state = {
    wod: new WodModel({}),
    errors: {},
    isLoading: true,
  };

  componentDidMount() {
    api
      .getPublishedWods({
        limit: 1,
        page: 1,
      })
      .then(([wods]) => {
        this.setState({
          wod: wods.length ? wods[0] : new WodModel({}),
          isLoading: false,
        });
      })
      .catch((err) => {
        const errors = err.errors;
        this.setState({
          errors,
          isLoading: false,
        });
      });
  }

  render() {
    const { wod, isLoading, errors } = this.state;

    let workoutsFlattened = wod.workouts.reduce((flattened, workout) => {
      if (workout.title)
        flattened.push(<h4 key={`${workout.id}-title`}>{workout.title}</h4>);
      if (workout.subtitles.length)
        flattened.push(
          <h4 key={`${workout.id}-subttiles`}>
            {workout.subtitles.join(', ')}
          </h4>
        );
      return [
        ...flattened,
        ...workout.exercises.map((exercise, idx) => (
          <p key={`${workout.id}-exercise-${idx}`}>{exercise}</p>
        )),
      ];
    }, []);
    if (workoutsFlattened.length > 8) {
      workoutsFlattened = workoutsFlattened.slice(0, 8);
      workoutsFlattened.push(<p key="end-delimiter">...</p>);
    }

    return (
      <div className="o-wrapper o-wrapper--small">
        <div className="c-wod u-flex u-flex-wrap">
          <Error message={errors} />
          {isLoading && <Loading />}
          {!isLoading && (
            <>
              <div className="c-wod__section u-flex u-flex-column">
                <Heading
                  subtitle={wod.name}
                  title={wod.date && wod.date.format('DD.MM.YYYY')}
                  src={kettlebell}
                  className="u-margin-bottom-large"
                />
                <Picture
                  src={wod.image.url ? wod.image.url : noImg}
                  srcWebp={wod.image.url ? null : noImgWebp}
                  alt="Workout of the Day Img"
                  className="c-wod__img u-margin-bottom"
                />
              </div>
              <div className="c-wod__section">
                <div className="c-wod__content">{workoutsFlattened}</div>
                <Button
                  to={`/wods/${wod.id}`}
                  btnType="tertiary"
                  className="c-btn--small u-margin-top-tiny c-wod__btn--small"
                >
                  виж повече
                </Button>
              </div>
              <div className="c-wod__btn-wrapper">
                <Button to="/wods" btnType="primary" className="c-wod__btn">
                  виж всички wods
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default Wod;
