import React from 'react';
import { Link } from 'react-router-dom';

import api from '../../../lib/api';
import Slider from 'react-slick';
import Nav from '../common/Nav';
import Loading from '../../../common/Loading';
import Error from '../../../common/Error';

// React Slick Carousel Styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../../scss/components/landing/_components.react-slick.scss';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true,
  autoplay: false,
};

class Header extends React.Component {
  state = {
    articles: [],
    errors: {},
    isLoading: true,
  };

  componentDidMount() {
    api
      .getPublishedArticles()
      .then((articles) => {
        this.setState({
          articles,
          isLoading: false,
        });
      })
      .catch((err) => {
        const errors = err.errors;
        this.setState({
          errors,
          isLoading: false,
        });
      });
  }

  render() {
    const { articles, isLoading, errors } = this.state;
    const { headerSecondary } = this.props;

    return (
      <header
        className={`c-header u-polygon-bg-bottom ${
          headerSecondary ? 'c-header--secondary' : null
        }`}
      >
        <div className="o-wrapper o-wrapper--large u-text-center u-padding-vertical">
          <Nav />
          {headerSecondary ? null : (
            <>
              {isLoading && <Loading />}
              <Error message={errors} />
              <Slider {...settings} className="c-slider u-margin-top-huge">
                {!isLoading &&
                  articles.map((article) => (
                    <div key={article.id}>
                      <h1 className="u-text-uppercase u-color-white">
                        {article.title}
                      </h1>
                      <p className="u-font-italic u-weight-light u-color-white u-font-size--medium u-text-display-space">
                        {article.description}
                      </p>
                      <Link
                        to={{
                          pathname: `/news/${article.id}`,
                        }}
                        className="c-btn c-btn--secondary u-margin-bottom-tiny u-margin-top"
                      >
                        научете повече
                      </Link>
                    </div>
                  ))}
              </Slider>
            </>
          )}
        </div>
      </header>
    );
  }
}

export default Header;
