import React from 'react';

import Button from '../../../common/Button';
import RoundImg from '../../../common/RoundImg';
import strength from '../../../img/icon-strength.svg';
import img from '../../../img/landing/img1-400-400.png';
import imgWebp from '../../../img/landing/webp/img1-400-400.webp';
import img2 from '../../../img/landing/img2-240-240.png';
import img2Webp from '../../../img/landing/webp/img2-240-240.webp';
import img3 from '../../../img/landing/img3-360-360.png';
import img3Webp from '../../../img/landing/webp/img3-360-360.webp';
import img4 from '../../../img/landing/img4-200-200.png';
import img4Webp from '../../../img/landing/webp/img4-200-200.webp';
import Heading from '../common/Heading';

const AboutUs = () => (
  <div className="o-wrapper o-wrapper--large">
    <div className="o-layout o-layout--center u-padding-bottom-huge u-padding-top">
      <div className="o-layout__item u-1/1 u-1/2@md">
        <Heading
          subtitle="за нас"
          title="crossfit varna"
          src={strength}
          className="u-margin-bottom-large"
        />
        <p>
          CrossFit Varna отваря врати през ноември 2014г. и оттогава бързо
          набира популярност сред спортуващото общество на града и страната. Тя
          е е една от трите зали в България, притежаваща лиценз да предлага
          програмата CrossFit.
        </p>
        <ul className="c-list--secondary">
          <li className="c-list--secondary__item">
            250 активно спортуващи атлети
          </li>
          <li className="c-list--secondary__item">
            400кв.м. модерно оборудвана зала
          </li>
          <li className="c-list--secondary__item">Квалифицирани треньори</li>
        </ul>
        <Button to="/about-us" btnType="primary">
          научете повече
        </Button>
      </div>
      <div className="o-layout__item u-1/1@xs u-1/2@md u-position-relative hide-on-md">
        <RoundImg
          src={img}
          srcWebp={imgWebp}
          width="200px"
          height="200px"
          style={{ position: 'absolute', left: '75px' }}
          className="hide-on-md"
        />
        <RoundImg
          src={img2}
          srcWebp={img2Webp}
          width="120px"
          height="120px"
          style={{ position: 'absolute', right: '50px', top: '-30px' }}
          className="hide-on-lg"
        />
        <RoundImg
          src={img3}
          srcWebp={img3Webp}
          width="180px"
          height="180px"
          style={{ position: 'absolute', right: '0px', top: '160px' }}
          className="hide-on-lg"
        />
        <RoundImg
          src={img4}
          srcWebp={img4Webp}
          width="100px"
          height="100px"
          style={{ position: 'absolute', left: '150px', top: '260px' }}
          className="hide-on-md"
        />
      </div>
    </div>
  </div>
);

export default AboutUs;
