import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import Logo from '../../../common/Logo';
import cfkLogo from '../../../img/landing/CFK-logo-v-tag-color-darkbg.png';
import CfLogo from './CfLogo';

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.nav = [
      { route: '/what-is-crossfit', name: 'Какво е CrossFit?' },
      { route: '/about-us', name: 'За Нас' },
      { route: '/news', name: 'Новини' },
      { route: '/terms', name: 'Общи условия' },
    ];
  }
  renderNav = (links) => {
    return links.map((link, i) => (
      <li key={i} className="o-list-inline__item c-footer__item">
        <NavLink
          className="c-footer__link u-font-size--small u-weight-light"
          activeClassName="active"
          to={`${link.route}`}
        >
          {link.name}
        </NavLink>
      </li>
    ));
  };
  render() {
    return (
      <div className="u-border-top">
        <footer className="c-footer">
          <div className="o-wrapper o-wrapper--large u-text-center u-padding-vertical">
            <div className="o-flx o-flx--center o-flx--justify-around o-flx--wrap">
              <Logo width="100px" />
              {/* <Link to="/kids">
                <img className="cfk-logo" src={cfkLogo} alt="CrossFit Kids" />
              </Link> */}
              <CfLogo width="150px" />
            </div>
            <div className="c-footer__content">
              <div className="c-footer__nav">
                <ul className="o-list-inline u-margin-bottom-none u-padding-vertical-tiny">
                  {this.renderNav(this.nav)}
                </ul>
                <span className="u-font-italic u-weight-light u-font-size--smaller u-opacity-half">
                  CrossFit Varna {new Date().getFullYear()} &reg; Всички права
                  запазени.
                </span>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
