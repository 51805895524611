import React from 'react';
import { Helmet } from 'react-helmet';

import Wrapper from '../common/Wrapper';
import Heading from '../common/Heading';

import cfvnIcon from '../../../img/icon-task.svg';

const Terms = () => {
  return (
    <Wrapper headerSecondary>
      <Helmet>
        <title>Общи условия | Общи условия за ползване на CrossFit Varna</title>
        <meta
          name="description"
          content="Общи условия | Общи условия за ползване на CrossFit Varna"
        />
      </Helmet>
      <div className="o-wrapper o-wrapper--large u-margin-bottom-huge">
        <Heading
          headingSecondary
          title="общи условия"
          src={cfvnIcon}
          className="u-margin-bottom-large"
        />
        {/* eslint-disable */}
        <p>Настоящите ОБЩИ УСЛОВИЯ уреждат взаимоотношенията между "Крослайф" ООД, наричано по-долу "crossfitvarna.com", от една страна, и Потребителите на Сайта (наричани по-долу Потребители), от друга.</p>

        <p>"Крослайф" ООД е дружество, регистрирано по Търговския закон на Република България, с ЕИК 203164423, със седалище и адрес на управление: гр. Варна, п.к. 9000, ул. "Димитър Икономов" №36, вх. А, ап. 54, имейл адрес: info@crossfitvarna.com, телефон: +359 886849469</p>

        <p>Моля прочетете изцяло публикуваните Общи условия преди да използвате Сайта. С визуализирането на Сайта всеки Потребител автоматично се задължава да спазва условията, описани по-долу.</p>

	      <p>Настоящият документ съдържа информация за дейността на "crossfitvarna.com", като уреждат отношенията между нас и всеки един от потребителите ни.</p>

        <p className="u-margin-bottom">Сайтът на дружеството е "crossfitvarna.com" и всички негови подстраници.</p>

	      <h3>УСЛУГИ</h3>
        <p>Чл. 1. На Сайта Потребителите могат да получават информация относно дейността, графика и цените на провежданите занятия  на "crossfitvarna.com".</p>
        <p>Чл. 2. Чрез контактната форма на Сайта Потребителите могат да отправят запитвания до "crossfitvarna.com".</p>
        <p>Чл. 3. Потребителите доброволно споделят личните си данни в контактната форма.</p>
        <p>Чл. 4. Единствените задължителни полета в контактната форма са името на Потребителя и неговото съобщение. Данните се събират, за да може "crossfitvarna.com" да отговори на отправеното запитване или предложение.</p>
        <p className="u-margin-bottom">Чл. 5. "crossfitvarna.com" изтрива личните данни на Потребителя след изтичане на 6 месечен срок от събирането им.</p>

        <h3>ИНТЕЛЕКТУАЛНА СОБСТВЕНОСТ</h3>
        <p>Чл. 6. Правата на интелектуална собственост върху всички материали и ресурси, разположени на уебсайта на "crossfitvarna.com" (включително наличните бази данни), са обект на закрила по Закона за авторското право и сродните права, принадлежат на "crossfitvarna.com" или на съответно указаното лице, преотстъпило правото на ползване на "crossfitvarna.com", и не могат да бъдат използвани в нарушение на действащото законодателство.</p>
        <p>Чл. 7. При копиране или възпроизвеждане на информация вън от допустимото, както и при всяко друго нарушение на правата на интелектуална собственост върху ресурсите на "crossfitvarna.com", "crossfitvarna.com" има правото да претендира обезщетение и за претърпените преки и косвени вреди в пълен размер.</p>
        <p>Чл. 8. Освен в случаите, когато е изрично уговорено, Потребителят не може да възпроизвежда, променя, заличава, публикува, разпространява и разгласява по друг начин информационните ресурси, публикувани на уебсайта на "crossfitvarna.com".</p>

        <p>Чл. 9.  "crossfitvarna.com" се задължава да полага дължимата грижа да осигури възможност на Потребителя за нормален достъп до предоставяните услуги.</p>
        <p className="u-margin-bottom">Чл. 10.  "crossfitvarna.com" си запазва правото да преустанови достъпа до предоставените услуги. "crossfitvarna.com” има право, но не и задължение, по своя преценка да изтрива информационни ресурси и материали, публикувани в сайта си.</p>

        <h3>ИЗМЕНЕНИЕ НА ОБЩИТЕ УСЛОВИЯ</h3>
        <p>Чл. 11. "crossfitvarna.com” се задължава да уведоми Потребителите за всяко изменение в настоящите общите условия в 7-дневен срок от настъпването на това обстоятелство на посочения от Потребителя имейл адрес.</p>
        <p>Чл. 12. Когато не е съгласен с измененията в общите условия, Потребителят има право да се откаже от договора, без да посочва причина и без да дължи обезщетение или неустойка. За да упражни това си право, Потребителят следва да уведоми "crossfitvarna.com” в срок от един месец от получаването на съобщението по предходния член.</p>
        <p className="u-margin-bottom">Чл. 13. В случай че Потребителят не упражни правото си да се откаже от договора по реда, уреден в настоящите общи условия, се счита, че изменението е прието от Потребителя без възражения.</p>

        <h3>ПРИЛОЖИМО ПРАВО</h3>
        <p className="u-margin-bottom">Чл. 14. По всички неуредени от настоящите Общи условия въпроси се прилагат разпоредбите на действащото законодателство на Република България.</p>

        <h2>ЗАЩИТА НА ЛИЧНИТЕ ДАННИ</h2>

        <h3>ЦЕЛИ</h3>
        <p>"crossfitvarna.com” събира и използва информацията за целите на:</p>

        <ul>
          <li>директен маркетинг</li>
          <li>изпълнение на договорните си задължения към Потребителите</li>
        </ul>

        <p>"crossfitvarna.com” обработва личните данни на потребителите на основание сключения между Потребителя и "crossfitvarna.com” договор.</p>
        <p>Потребителят се съгласява с обработването на личните му данни за целите на директния маркетинг чрез маркиране на чек бокс.</p>
        <p>Потребителят може да се откаже по всяко време от даденото съгласие чрез маркиране на чекбокс, който се намира в десния ъгъл на страницата.</p>
        <p className="u-margin-bottom">Личните данни на Потребителите се съхраняват за период от 5 години.</p>

        <h3>ПРАВА НА ПОТРЕБИТЕЛИТЕ</h3>
        <p>Всеки Потребител на сайта се ползва с всички права за защита на личните данни съгласно българското законодателство и правото на Европейския съюз. Всеки Потребител има право на:</p>
        <ul>
          <li>Информираност (във връзка с обработването на личните му данни от администратора);</li>
          <li>Достъп до собствените си лични данни;</li>
          <li>Коригиране (ако данните са неточни);</li>
          <li>Изтриване на личните данни (право „да бъдеш забравен“);</li>
          <li>Ограничаване на обработването от страна на администратора или обработващия лични данни;</li>
          <li>Преносимост на личните данни между отделните администратори;</li>
          <li>Възражение спрямо обработването на негови лични данни;</li>
          <li>Субектът на данни има право и да не бъде обект на решение, основаващо се единствено на автоматизирано обработване, включващо профилиране, което поражда правни последствия за субекта на данните или по подобен начин го засяга в значителна степен;</li>
          <li>Право на защита по съдебен или административен ред, в случай, че правата на субекта на данни са били нарушени.</li>
        </ul>

        <p>Потребителят може да поиска изтриване, ако е налице едно от следните условия:</p>
        <ul>
          <li>Личните данни повече не са необходими за целите, за които са били събрани или обработвани по друг начин;</li>
          <li>Потребителят оттегля своето съгласие, върху което се основава обработването на данните и няма друго правно основание за обработването;</li>
          <li>Потребителят данните възразява срещу обработването и няма законни основания за обработването, които да имат преимущество;</li>
          <li>Личните данни са били обработвани незаконосъобразно;</li>
          <li>Личните данни трябва да бъдат изтрити с цел спазването на правно задължение по правото на Съюза или правото на държава членка, което се прилага спрямо администратора;</li>
          <li>Личните данни са били събрани във връзка с предлагането на услуги на информационното общество на деца и съгласието е дадено от носещия родителска отговорност за детето.</li>
        </ul>

        <p>Потребителят има право да ограничи обработването на своите лични данни от страна на администратора, когато:</p>
        <ul>
          <li>Оспори точността на личните данни. В този случай ограничаването на обработването е за срок, който позволява на администратора да провери точността на личните данни;</li>
          <li>Обработването е неправомерно, но Потребителят не желае личните данни да бъдат изтрити, а изисква вместо това ограничаване на използването им;</li>
          <li>Администраторът не се нуждае повече от личните данни за целите на обработването, но Потребителят ги изисква за установяването, упражняването или защитата на правни претенции;</li>
          <li>Възразява срещу обработването в очакване на проверка дали законните основания на администратора имат преимущество пред интересите на Потребителя.</li>
        </ul>

        <p>Право на преносимост.</p>
        <p>Субектът на данните има право да получи личните данни, които го засягат и които той е предоставил на администратор, в структуриран, широко използван и пригоден за машинно четене формат и има правото да прехвърли тези данни на друг администратор без възпрепятстване от администратора, на когото личните данни са предоставени, когато обработването е основано на съгласие или на договорно задължение и обработването се извършва по автоматизиран начин. Когато упражнява правото си на преносимост на данните, субектът на данните има право да получи и пряко прехвърляне на личните данни от един администратор към друг, когато това е технически осъществимо.</p>

        <p>Право на възражение.</p>
        <p>Потребителите имат право да възразят пред администратора срещу обработването на личните им данни. Администраторът на лични данни е длъжен да прекрати обработването, освен ако не докаже, че съществуват убедителни законови основания за обработването, които имат предимство пред интересите, правата и свободите на субекта на данни, или за установяването, упражняването или защитата на правни претенции. При възразяване срещу обработването на лични данни за целите на директния маркетинг обработването следва да се прекрати незабавно.</p>

        <p>Жалба до надзорния орган</p>
        <p className="u-margin-bottom">Всеки Потребител има право да подаде жалба срещу незаконосъобразно обработване на личните му данни до Комисия за защита на личните данни или до компетентния съд.</p>

        <h3>ЗАДЪЛЖЕНИЯ НА АДМИНИСТРАТОРА НА ЛИЧНИ ДАННИ:</h3>
        <p>Администраторът на лични данни има следните задължения:</p>
        <ul>
          <li>Обработва на данните в съответствие с принципите за защита на личните данни, заложени в регламента, като е в състояние да докаже това (отчетност);</li>
          <li>Осигурява защита на данните на етапа на проектирането и по подразбиране;</li>
          <li>Уведомява надзорния орган и субекта на данни в случай на нарушаване на сигурността на личните данни, както и документиране на всяко нарушение на сигурността на личните данни, в т.ч. фактите, свързани с нарушението, последиците от него, предприетите действия за справяне с нарушението;</li>
          <li>Извършва оценка на въздействието върху защитата на данните;</li>
          <li>Прилага подходящи технически и организационни мерки за осигуряване на сигурност на данните, като:</li>
          <ul>
            <li>Псевдонимизация;</li>
            <li>Криптиране;</li>
            <li>Гарантиране на постоянна поверителност, цялостност, наличност и устойчивост на системите и услугите за обработване;</li>
            <li>Своевременно възстановяване на наличността и достъпа до личните данни в случай на физически или технически инцидент;</li>
            <li>Редовно изпитване, преценяване и оценка на ефективността на техническите и организационните мерки;</li>
            <li>Сътрудничество с надзорния орган за защита на личните данни при изпълнение на задълженията, произтичащи то регламента.</li>
            <li>Изготвя и прилага вътрешни процедури относно приемане, разглеждане и отговаряне в едномесечен срок на искания от Потребителите за упражняване на правата им като субекти на лични данни.</li>
          </ul>
        </ul>

        <h3>ПОДДЪРЖАНЕ НА РЕГИСТЪР</h3>
        <p>"crossfitvarna.com” поддържа регистър на дейностите по обработване, за които отговоря. Този регистър съдържа цялата по-долу посочена информация:</p>
        <ul>
          <li>Името и координатите за връзка на администратора;</li>
          <li>Целите на обработването;</li>
          <li>Описание на категориите субекти на данни и на категориите лични данни;</li>
          <li>Категориите получатели, пред които са или ще бъдат разкрити личните данни, включително получателите в трети държави или международни организации;</li>
          <li>Когато е възможно, предвидените срокове за изтриване на различните категории данни;</li>
          <li>Когато е възможно, общо описание на техническите и организационни мерки за сигурност.</li>
        </ul>

        <h3>ОРГАН РЕГУЛИРАЩ ДЕЙНОСТТА</h3>
        <p>Органът, регулиращ дейността на "crossfitvarna.com” е Комисия за защита на личните данни (КЗЛД), със следните координати:</p>

        <p>За КЗЛД:</p>
        <ul className="u-margin-bottom-large">
          <li>Уебсайт: <a href="https://www.cpdp.bg" target="_blank" rel="noopener noreferrer">https://www.cpdp.bg</a></li>
          <li>тел: 02/91-53-518</li>
          <li>email: kzld@cpdp.bg</li>
          <li>адрес: София 1592, бул. „Проф. Цветан Лазаров” № 2</li>
        </ul>

        <h2>Политика за „бисквитки“</h2>
        <h3>Използване на „бисквитки“</h3>
        <p className="u-margin-bottom">„Бисквитките“ са кратки текстови файлове или малки пакети от информация, които се съхраняват чрез Интернет браузера на Вашето крайно устройство (компютър, таблет, лаптоп или мобилен телефон), когато посещавате различни сайтове и страници в Интернет. Основната цел на „бисквитките“ е да правят потребителя разпознаваем, когато той се завръща отново на Уебсайта. Някои „бисквитки“ имат и по-специфично приложение, като например да запаметяват потребителското поведение на сайта и да улесняват потребителя при ползването на Уебсайта. Повече информация за това как функционират „бисквитките“ можете да намерите в Интернет.</p>

        <h3>Как се използват „бисквитки“ на този Уебсайт?</h3>
        <p className="u-margin-bottom">Ние използваме „бисквитки“ на този Уебсайт предимно с цел улесняване на ползваемостта на сайта, подобряване на неговата работа и съхраняване на информация за потребителското поведение. При този процес не се съхраняват никакви лични данни, т.е. чрез “бисквитките“ на сайта не може да Ви идентифицираме като личност, поради което спрямо събирането на тази информация не се прилага Закона за защита на личните данни. Събраната информация от „бисквитки“ обикновено се използва в обобщен вид с цел анализ на потребителското поведение на Уебсайта, което ни позволява да подобряваме функционалността на сайта, потребителските пътеки и ползваното съдържание.</p>

        <h3>Какви „бисквитки“ се използват на този Уебсайт?</h3>

        <p>Сесийни „бисквитки“</p>
        <p className="u-margin-bottom">Този тип „бисквитки“ Ви улеснява в ползването на сайта, като те съхраняват информация временно, само в рамките на сесията на използвания браузер. Обикновено информацията, която се съхранява чрез тях, е какви стоки или услуги сте добавили в количката, кои страници на сайта сте посетили и как сте стигнали до дадена информация. Тези „бисквитки“ не събират информация от Вашето крайно устройство и се изтриват автоматично, когато напуснете Уебсайта или прекратите сесията на Вашия браузър.</p>

        <p>Постоянни "бисквитки"</p>
        <p className="u-margin-bottom">Те ни дават възможност да съхраняваме конкретна информация за сърфирането, като например анализиране посещенията на сайта, как вие сте достигнали до Уебсайта, какви страници сте прегледали, какви опции сте избрали, както и накъде сте се насочили през този Уебсайт. Проследяването на тази информация, ни дава възможност да правим подобрения на Уебсайта, включително да коригираме грешки и да разширяваме съдържанието. Срокът на съхранение на този вид “бисквитки” варира според конкретното им предназначение.</p>

        <p>"Бисквитки" на трета страна</p>
        <p className="u-margin-bottom">На нашия Уебсайт има препратки към други сайтове или вградено съдържание от други сайтове, например от Facebook, YouTube, Twitter, Google+, LinkedIn, уебсайтове на партньори. Възможно е при посещаването на тези сайтове или отварянето на съдържанието от тях, да се складират на Вашето крайно устройство „бисквитки“ от тези уебсайтове. Именно тези „бисквитки“ се дефинират като „бисквитки на трети страни“, като нямаме контрол върху генерирането и управлението на тези „бисквитки“. За това Ви съветваме да потърсите информация за тях и за начина им на управление на уебсайтовете на съответните трети страни.</p>

        <h3>Как мога да управлявам използването на „бисквитки“ от този Уебсайт?</h3>
        <p className="u-margin-bottom">Всички браузъри позволяват управлението на „бисквитки“ от специално създадена за целта папка на Вашия браузър. Можете да блокирате получаването на „бисквитки“, да изтриете всички или част от тях или да зададете Вашите настройки за предпочитания по отношение на използването на „бисквитки“ преди да инициирате посещение на сайта ни. Имайте предвид, че изтриването или блокирането на „бисквитки“ може да повлияе неблагоприятно върху функциите на нашия Уебсайт, а оттам и върху Вашето потребителско преживяване на него.</p>

        <h3>Изключване или блокиране на „бисквитки“</h3>
        <p>Контролирането, изключването или блокирането на „бисквитките“ се управлява от настройките на Вашия браузер. Имайте предвид, че пълната забрана на използването на всички „бисквитки“, може да рефлектира върху функционалното представяне на сайта, неговата ефективност и достъпването на определена информация.</p>
      {/* eslint-enable */}
      </div>
    </Wrapper>
  );
};

export default Terms;
