import React, { Component } from 'react';

import api from '../../../lib/api';
import TextInput from '../../../common/TextInput';
import InputFeedback from '../../../common/InputFeedback';
import Logo from '../../../common/Logo';

class ResetPassword extends Component {
  state = {
    password: '',
    confirmPassword: '',
    errors: {},
  };

  onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { password, confirmPassword } = this.state;
    if (password !== confirmPassword) {
      return this.setState({
        errors: {
          confirmPassword: 'Паролите не съвпадат',
        },
      });
    }

    api
      .resetPassword(this.props.match.params.code, password)
      .then(({ role }) => {
        // automatically log in user and reroute to proper dashboard
        localStorage.setItem('role', role);
        window.location = `/${role}`;
      })
      .catch((err) => {
        this.setState({ errors: err.errors });
      });
  };

  render() {
    const { password, confirmPassword, errors } = this.state;
    return (
      <div className="c-header u-flex u-flex-1 u-align-items-center u-justify-content-center">
        <form
          className="c-form-holder u-flex u-flex-column u-align-items-center"
          onSubmit={this.onSubmit}
        >
          <Logo
            width="100px"
            isDefault
            className="u-margin-bottom u-padding-small"
          />
          <h2 className="u-text-uppercase u-color-white">Смяна на парола</h2>
          <div className="u-flex u-flex-column u-align-items-center u-margin-bottom-tiny">
            <TextInput
              name="password"
              value={password}
              onChange={this.onChange}
              placeholder="Нова парола"
              error={errors.password}
              type="password"
            />
            <TextInput
              name="confirmPassword"
              value={confirmPassword}
              onChange={this.onChange}
              placeholder="Потвърди новата парола"
              error={errors.confirmPassword}
              type="password"
            />
            <InputFeedback message={errors.base} />
          </div>
          <button type="submit" className="c-btn c-btn--primary">
            потвърди
          </button>
        </form>
      </div>
    );
  }
}

export default ResetPassword;
