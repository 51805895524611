import React from 'react';
import { Helmet } from 'react-helmet';

import Wrapper from '../common/Wrapper';
import AboutUs from './AboutUs';
import Wod from './Wod';
import Prices from './Prices';
import ContactUs from './ContactUs';

const Home = () => (
  <Wrapper>
    <Helmet>
      <title>CrossFit Varna | За нас, Цени и График, Контакти</title>
      <meta
        name="description"
        content="CrossFit Varna | За нас, Цени и График, Контакти"
      />
    </Helmet>
    <Wod />
    <AboutUs />
    <Prices />
    <ContactUs />
  </Wrapper>
);

export default Home;
