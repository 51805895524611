import React from 'react';
import { FadeTransform } from 'react-animation-components';

import Header from './Header';
import Footer from './Footer';

const Wrapper = ({ children, headerSecondary }) => (
  <>
    {headerSecondary ? <Header headerSecondary /> : <Header />}
    <FadeTransform
      enterTransform="translateY(0)"
      exitTransform="translateY(200px)"
      duration={400}
      timingFn="ease-in"
      in
      className="content"
    >
      <div>{children}</div>
    </FadeTransform>

    <Footer />
  </>
);

export default Wrapper;
