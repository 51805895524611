const isUserLoggedIn = () => {
  if (process.env.BUILD_TARGET === 'client') {
    const role = localStorage.getItem('role');
    return !!role;
  }

  return false;
};

export default isUserLoggedIn;
