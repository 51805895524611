import React, { Component } from 'react';

import api from '../../../lib/api';
import TextInput from '../../../common/TextInput';
import InputFeedback from '../../../common/InputFeedback';
import Logo from '../../../common/Logo';
import ForgottenPasswordModal from './ForgottenPasswordModal';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      role: null,
      errors: {},
      showModal: false,
    };
  }

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;

    api
      .login({
        email,
        password,
      })
      .then(({ role }) => {
        localStorage.setItem('role', role);
        this.setState({ role });
      })
      .catch((err) => {
        this.setState({ errors: err.errors });
      });
  };

  render() {
    const { role } = this.state;

    if (role) {
      window.location = `/${role}`;
      return null;
    } else {
      const { email, password, errors, showModal } = this.state;
      return (
        <div className="c-header u-flex u-flex-1 u-align-items-center u-justify-content-center">
          <form
            className="c-form-holder u-flex u-flex-column u-align-items-center"
            onSubmit={this.onSubmit}
          >
            <Logo
              width="100px"
              isDefault
              className="u-margin-bottom u-padding-small"
            />
            <div className="u-flex u-flex-column u-align-items-center">
              <TextInput
                name="email"
                value={email}
                onChange={this.onChange}
                placeholder="Email"
                error={errors.email}
              />
              <TextInput
                name="password"
                value={password}
                onChange={this.onChange}
                placeholder="Парола"
                error={errors.password}
                type="password"
              />
              <InputFeedback message={errors.base} />
              <button type="submit" className="c-btn c-btn--primary">
                вход
              </button>
              <button
                type="button"
                onClick={this.handleOpenModal}
                className="c-btn c-btn--simple u-margin-top"
              >
                {' '}
                забравена парола?
              </button>
            </div>
          </form>
          <ForgottenPasswordModal
            showModal={showModal}
            onCloseModal={this.handleCloseModal}
          />
        </div>
      );
    }
  }
}

export default Login;
