import React, { Component } from 'react';

import isUserLoggedIn from '../../../../lib/isUserLoggedIn';
import defaultAvatar from '../../../../img/landing/header-bg-100-100.jpg';

class Comment extends Component {
  state = {
    showNameInput: false,
    authorName: '',
    commentText: '',
    comment: this.props.comment,
    subcommentFormVisible: false,
  };

  componentDidMount = () => {
    this.setState({
      showNameInput: !isUserLoggedIn(),
    });
  };

  getCommentName = (comment) => {
    return comment.author.user.id
      ? `${comment.author.user.firstName} ${comment.author.user.lastName}`
      : comment.author.name;
  };

  getCommentImg = (comment) => {
    const { user } = comment.author;
    if (user.id && user.avatar.id) {
      return user.avatar.url;
    }
    return defaultAvatar;
  };

  onCommentChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onSubcommentSubmit = (e) => {
    e.preventDefault();
    const { comment, authorName, commentText } = this.state;
    this.props.onSubcommentSubmit({
      parentId: comment.id,
      authorName,
      text: commentText,
    });
  };

  render() {
    const { comment, subcommentFormVisible } = this.state;
    return (
      <div className="c-comment">
        <div>
          <div className="c-comment__header">
            <div className="u-flex u-align-items-center">
              <img
                src={this.getCommentImg(comment)}
                alt="User Avatar"
                className="c-comment__img u-img-round u-img-responsive"
              />
              <p className="u-margin-left-small u-margin-bottom-none u-weight-normal">
                {this.getCommentName(comment)}
              </p>
            </div>
            <div>
              <p className="u-opacity-half u-font-size--small u-weight-light u-margin-bottom-none">
                {comment.createdAt &&
                  comment.createdAt.format('Do MMMM YYYY, HH:mm')}
              </p>
            </div>
          </div>
          <div className="c-comment__content u-align-items-end">
            <div className="u-margin-top">
              <p>{comment.text}</p>
            </div>
            <button
              className="c-btn c-btn--tertiary u-padding-bottom-none u-text-right"
              onClick={() =>
                this.setState({
                  subcommentFormVisible: !subcommentFormVisible,
                })
              }
            >
              {subcommentFormVisible ? 'затвори' : 'отговори'}
            </button>
          </div>
        </div>
        {/* Subcomment */}
        {comment.comments.map((subcomment) => (
          <div
            key={subcomment.id}
            className="u-margin-top u-margin-left-large u-border-top"
          >
            <div className="c-comment__header u-margin-top">
              <div className="u-flex u-align-items-center">
                <img
                  src={this.getCommentImg(subcomment)}
                  alt="User Avatar"
                  className="c-comment__img u-img-round u-img-responsive"
                />
                <p className="u-margin-left-small u-margin-bottom-none u-weight-normal">
                  {this.getCommentName(subcomment)}
                </p>
              </div>
              <div>
                <p className="u-opacity-half u-font-size--small u-weight-light u-margin-bottom-none">
                  {subcomment.createdAt &&
                    subcomment.createdAt.format('Do MMMM YYYY, HH:mm')}
                </p>
              </div>
            </div>
            <div className="c-comment__content u-align-items-end">
              <div className="u-margin-top">
                <p>{subcomment.text}</p>
              </div>
            </div>
          </div>
        ))}
        {subcommentFormVisible && (
          <div className="u-border-top u-padding-top u-margin-top u-flex u-justify-content-between u-align-items-center">
            <form onSubmit={this.onSubcommentSubmit}>
              <div className="u-padding-small">
                {this.state.showNameInput && (
                  <input
                    className="c-input u-margin-bottom"
                    placeholder="Име"
                    name="authorName"
                    value={this.state.authorName}
                    onChange={this.onCommentChange}
                  />
                )}
                <textarea
                  className="c-input c-textarea c-textarea--secondary u-margin-bottom-small"
                  type="text"
                  placeholder="Коментар..."
                  name="commentText"
                  value={this.state.commentText}
                  onChange={this.onCommentChange}
                />
                <button type="submit" className="c-btn c-btn--primary">
                  изпрати
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default Comment;
