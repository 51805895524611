import React from 'react';
import ReactSVG from 'react-svg';

const Heading = ({ headingSecondary, src, subtitle, title, className }) => (
  <div>
    {headingSecondary ? (
      <div
        className={`c-heading c-heading--secondary u-margin-bottom u-flex u-flex-column u-align-items-start ${className}`}
      >
        <ReactSVG
          className="c-heading__icon u-margin-bottom-small"
          svgClassName="u-img-responsive"
          svgStyle={{
            width: 50,
            height: 50,
          }}
          src={src}
        />
        <div>
          <h1 className="u-text-uppercase u-margin-bottom-none u-text-left">
            {title}
          </h1>
        </div>
      </div>
    ) : (
      <div
        className={`c-heading u-flex u-align-items-center u-margin-bottom ${className}`}
      >
        <ReactSVG
          className="c-heading__icon u-margin-right"
          svgClassName="u-img-responsive"
          svgStyle={{
            width: 50,
            height: 50,
          }}
          src={src}
        />
        <div>
          <span className="c-heading__sub">{subtitle}</span>
          <h2 className="u-text-uppercase u-margin-bottom-none u-text-left">
            {title}
          </h2>
        </div>
      </div>
    )}
  </div>
);

export default Heading;
