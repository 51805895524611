import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import api from '../../../lib/api';
import Loading from '../../../common/Loading';
import Error from '../../../common/Error';
import Wrapper from '../common/Wrapper';
import Heading from '../common/Heading';
import Item from './Item';

import newsIcon from '../../../img/icon-news.svg';

class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      articles: [],
      errors: {},
      isLoading: true,
    };
  }

  componentDidMount() {
    api
      .getPublishedArticles()
      .then((articles) => {
        this.setState({
          articles,
          isLoading: false,
        });
      })
      .catch((err) => {
        const errors = err.errors;
        this.setState({
          errors,
          isLoading: false,
        });
      });
  }

  render() {
    const { articles, isLoading, errors } = this.state;
    return (
      <Wrapper headerSecondary>
        <Helmet>
          <title>Новини | Списък с всички новини от CrossFit Varna</title>
          <meta
            name="description"
            content="Новини | Списък с всички новини от CrossFit Varna"
          />
        </Helmet>
        <div className="o-wrapper o-wrapper--large u-margin-bottom-huge">
          <Heading
            headingSecondary
            title="новини"
            src={newsIcon}
            className="u-margin-bottom-large"
          />
          <Error message={errors} />
          {isLoading && <Loading />}
          {!isLoading &&
            articles.map((article) => (
              <Item key={article.id} article={article} />
            ))}
        </div>
      </Wrapper>
    );
  }
}

export default News;
