import React from 'react';
import { Helmet } from 'react-helmet';

import RoundImg from '../../../common/RoundImg';
import boris from '../../../img/landing/boris-400-400.png';
import borisWebp from '../../../img/landing/webp/boris-400-400.webp';
import svetoslav from '../../../img/landing/svetoslav-400-400.png';
import svetoslavWebp from '../../../img/landing/webp/svetoslav-400-400.webp';
import miroslav from '../../../img/landing/miroslav-400-400.png';
import miroslavWebp from '../../../img/landing/webp/miroslav-400-400.webp';
import alexander from '../../../img/landing/alexander-400-400.png';
import alexanderWebp from '../../../img/landing/webp/alexander-400-400.webp';
import strength from '../../../img/icon-strength.svg';

import Wrapper from '../common/Wrapper';
import Heading from '../common/Heading';

const AboutUs = () => (
  <Wrapper headerSecondary>
    <Helmet>
      <title>
        За Нас | Научете повече за нас, нашите треньори и събитията, които
        организираме
      </title>
      <meta
        name="description"
        content="За Нас | Научете повече за нас, нашите треньори и събитията, които организираме"
      />
    </Helmet>
    <div className="o-wrapper o-wrapper--large u-margin-bottom-huge">
      <Heading
        headingSecondary
        type="headingSecondary"
        src={strength}
        title="за нас"
        className="u-margin-bottom-large"
      />
      <p className="u-margin-bottom">
        CrossFit Varna отваря врати през ноември 2014г. и оттогава бързо набира
        популярност сред спортуващото общество на града и страната. Тя е е една
        от трите зали в България, притежаваща лиценз да предлага програмата
        „КросФит“.
      </p>

      <p>
        Нашата мисия е да предлагаме възможно най-добрата услуга, с цел
        подобряване физическото състояние на хората и качеството им на живот.
        Програмата за сила и кондиция - „КросФит“, е доказала своята ефективност
        в глобален мащаб, а резултатите и ползите, които носи, са несравними.
      </p>
      <p>Защо го правим?</p>
      <p>Отговорът на този въпрос е следния:</p>
      <p>
        Защото искаме да помагаме на хората! Да внесем „фитнес“ в живота им и да
        развием една общност, в която всички се подкрепяме един друг и ни е
        приятно да прекарваме време заедно.
      </p>
      <p>
        Стремим се да подобрим самочувствието на всеки, като предлагаме една
        програма за сила и кондиция, благодарение на която хората се чувстват
        във „Формата на живота си“.
      </p>
      <p className="u-margin-bottom">
        „КросФит“ се превърна в призвание за нас! Преподаваме основни умения за
        движение на човешкото тяло, които имат изключително силно въздействие и
        променят всеки аспект от живота на съвременния човек.
      </p>

      <p>
        Вече имаме сериозен опит в организирането на различни спортни
        мероприятия, провели се на локално ниво. Най-амбициозното и голямо по
        мащаб е{' '}
        <a
          href="http://varnathrowdown.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Varna Throwdown
        </a>
        , което се организира вече три години подред и се затвърждава като
        най-голямото „КросФит“ състезание в България.
      </p>
      <p>
        Амбициите ни са Varna Throwdown да стане неизменна част от календара на
        големите „КросФит“ турнири, привличащо елитни атлети от цяла Европа със
        своята атрактивна локация и сериозен награден фонд.
      </p>
      <ul className="c-list--secondary">
        <li className="c-list--secondary__item">
          250 активно спортуващи атлети
        </li>
        <li className="c-list--secondary__item">
          400кв.м. модерно оборудвана зала
        </li>
        <li className="c-list--secondary__item">Квалифицирани треньори</li>
      </ul>
      {/* TODO: enable again once we have the updated trainers list */}
      {/* <h2 className="u-text-uppercase u-margin-top-large">Нашите треньори</h2>
      <div className="u-flex u-justify-content-around u-flex-wrap">
        <div className="u-display-flex u-flex-column u-align-items-center u-text-center u-margin-small">
          <RoundImg
            src={boris}
            srcWebp={borisWebp}
            width="200px"
            height="200px"
            style={{ position: 'relative' }}
          />
          <h5 className="u-margin-top-small">Борис Трендафилов</h5>
        </div>
        <div className="u-display-flex u-flex-column u-align-items-center u-text-center u-margin-small">
          <RoundImg
            src={svetoslav}
            srcWebp={svetoslavWebp}
            width="200px"
            height="200px"
            style={{ position: 'relative' }}
          />
          <h5 className="u-margin-top-small">Светослав Гърков</h5>
        </div>
        <div className="u-display-flex u-flex-column u-align-items-center u-text-center u-margin-small">
          <RoundImg
            src={miroslav}
            srcWebp={miroslavWebp}
            width="200px"
            height="200px"
            style={{ position: 'relative' }}
          />
          <h5 className="u-margin-top-small">Мирослав Гочев</h5>
        </div>
        <div className="u-display-flex u-flex-column u-align-items-center u-text-center u-margin-small">
          <RoundImg
            src={alexander}
            srcWebp={alexanderWebp}
            width="200px"
            height="200px"
            style={{ position: 'relative' }}
          />
          <h5 className="u-margin-top-small">Александър Трендафилов</h5>
        </div>
      </div> */}
      <h2 className="u-text-uppercase u-margin-top-large">varna throwdown</h2>
      <p>
        <a
          href="http://varnathrowdown.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Varna Throwdown
        </a>{' '}
        е състезание, вдъхновено от популярната тренировъчна и състезателна
        програма „КросФит“, чиято главна цел е развиването на универсалния атлет
        - еднакво силен, бърз, издръжлив и умел в различни сложни движения.
        CrossFit Varna организира VNTD за четвърта поредна година, с желанието
        да събере най-добрите атлети от страната и Европа в едно атрактивно
        съревнование на силата, издръжливостта и волята.
      </p>

      <p>
        В миналогодишното издание на турнира участие взеха над 300 атлети (от 17
        различни Европейски страни) в различни категории (вкл. ветерани и юноши
        до 18г.). Varna Throwdown се превърна в най-голямото „КросФит“ събитие
        за годината в страната, което привлича многобройна публика и предизвика
        широк отзвук в различни местни и национални спортни медии.
      </p>

      <p>
        През 2019 година, в която Варна е избран за европейска столица на
        спорта, изданието на турнира ще бъде част от календара на събитието и ще
        се проведе отново с помощта и съдействието на „Община Варна“. Датите са
        8ми и 9ти Юни, като ни е предоставена атрактивна локация - „Слъчевата
        алея“ в Морската градина на Варна, където ще бъде изградена специална
        арена с площ над 600 кв/м.
      </p>
      <a
        href="http://varnathrowdown.com"
        target="_blank"
        rel="noopener noreferrer"
        className="c-btn c-btn--primary u-margin-top-small"
      >
        научете повече
      </a>
    </div>
  </Wrapper>
);

export default AboutUs;
