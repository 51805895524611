import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroll-component';

import api from '../../../lib/api';
import Loading from '../../../common/Loading';
import Error from '../../../common/Error';
import Wrapper from '../common/Wrapper';
import Heading from '../common/Heading';
import WodsSearchBar from '../../../common/WodsSearchBar';
import WodCard from '../../../common/WodCard';

import kettlebell from '../../../img/icon-kettlebell.svg';

class Wods extends Component {
  state = {
    wods: [],
    errors: {},
    isLoading: true,
    headers: {},
    page: 1,
  };

  componentDidMount() {
    this.fetchWods(this.state.page);
  }

  fetchWods = (page) => {
    api
      .getPublishedWods({
        limit: 6,
        page: page || 1,
      })
      .then((res) => {
        const wods = res[0];
        const headers = res[1];
        this.setState({
          wods: [...this.state.wods, ...wods],
          isLoading: false,
          headers,
          page,
        });
      })
      .catch((err) => {
        const errors = err.errors;
        this.setState({
          errors,
          isLoading: false,
        });
      });
  };

  fetchMoreData = () => {
    const nextPage = this.state.page + 1;
    this.fetchWods(nextPage);
  };

  navigateToSingleWod = (wod) => {
    if (!wod) return;
    this.props.history.push(`/wods/${wod.id}`);
  };

  render() {
    const { wods, isLoading, errors, headers } = this.state;
    return (
      <Wrapper headerSecondary>
        <Helmet>
          <title>WODs | Списък с всички тренировки по дни</title>
          <meta
            name="description"
            content="WODs | Списък с всички тренировки по дни"
          />
        </Helmet>
        <div className="o-wrapper o-wrapper--large u-margin-bottom-huge">
          <Heading
            headingSecondary
            title="wods"
            src={kettlebell}
            className="u-margin-bottom-large"
          />
          <div className="u-margin-bottom">
            <WodsSearchBar
              placeholder="Търси тренировка по име, формат, упражнение или описание"
              searchWodsAction={api.getPublishedWods.bind(api, {})}
              onSelected={this.navigateToSingleWod}
            />
          </div>
          <Error message={errors} />
          <div className="o-layout u-flex u-flex-wrap u-justify-content-around">
            {isLoading && <Loading />}
            {!isLoading && (
              <InfiniteScroll
                dataLength={wods.length}
                next={this.fetchMoreData}
                hasMore={headers.total > wods.length}
                loader={<Loading className="c-loading--fetch" />}
                style={{ overflow: 'visible' }}
                endMessage={
                  <p className="u-text-center u-text-uppercase u-weight-bold u-padding-top">
                    Това са всички тренировки
                  </p>
                }
              >
                {wods.map((wod) => (
                  <WodCard key={wod.id} wod={wod} goToUrl={`/wods/${wod.id}`}>
                    <button className="c-btn c-btn--primary c-btn--small">
                      виж повече
                    </button>
                  </WodCard>
                ))}
              </InfiniteScroll>
            )}
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default Wods;
