import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const withRecaptcha = (WrappedComponent) =>
  class Recaptcha extends Component {
    constructor(props) {
      super(props);
      this.recaptchaRef = React.createRef();
    }

    getRecaptcha = () => {
      return this.recaptchaRef.current.execute();
    };

    render() {
      return (
        <>
          <ReCAPTCHA
            ref={this.recaptchaRef}
            size="invisible"
            sitekey={process.env.RAZZLE_RECAPTCHA_SITE_KEY}
          />
          <WrappedComponent getRecaptcha={this.getRecaptcha} {...this.props} />
        </>
      );
    }
  };

export default withRecaptcha;
