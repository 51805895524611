import React, { Component } from 'react';
import Modal from 'react-modal';

import api from '../../../lib/api';
import TextInput from '../../../common/TextInput';

class ForgottenPasswordModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      errors: {},
      success: null,
    };
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    api
      .requestResetPassword(this.state.email)
      .then((res) => {
        this.setState({
          success:
            'Линкът за възстановяне на паролата беше успешно изпратен на посочения имейл.',
        });
      })
      .catch((err) => {
        this.setState({ errors: err.errors });
      });
  };

  render() {
    const customStyles = {
      content: {
        maxWidth: '500px',
        margin: '100px auto 20px',
        bottom: 'auto',
        display: 'flex',
        flexDirection: 'column',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    };
    // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#root');

    const { email, errors, success } = this.state;
    return (
      <Modal
        isOpen={this.props.showModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="o-flx o-flx--justify-end u-margin-bottom-tiny">
          <button
            onClick={this.props.onCloseModal}
            type="button"
            className="c-btn c-btn--icon c-btn--square u-bg-color-white u-color-bright-gray u-border"
          >
            ×
          </button>
        </div>
        <h2 className="u-text-center u-margin-bottom-small">
          Въведи валиден имейл
        </h2>
        <p className="u-text-center u-margin-bottom-none">
          Ще получиш имейл с линк, съдържащ новата ти парола.
        </p>
        <form
          className="c-form-holder u-flex u-flex-column u-align-items-center"
          onSubmit={this.onSubmit}
        >
          <TextInput
            name="email"
            value={email}
            onChange={this.onChange}
            placeholder="Email"
            error={errors.email}
            success={success}
          />

          <button type="submit" className="c-btn c-btn--primary">
            изпрати
          </button>
        </form>
      </Modal>
    );
  }
}

export default ForgottenPasswordModal;
