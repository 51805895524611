import React from 'react';
import { NavLink } from 'react-router-dom';

import Logo from '../../../common/Logo';
import Button from '../../../common/Button';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileMenuOpen: false,
    };
    this.navInternalLinks = [
      { route: '/', name: 'Начало' },
      { route: '/wods', name: 'WODs' },
      { route: '/news', name: 'Новини' },
      // { route: '/kids', name: 'За деца' },
      { route: '/about-us', name: 'За Нас' },
    ];
  }

  handleMobileMenuClose = () => this.setState({ isMobileMenuOpen: false });

  /**
   * Render iternal links in nav
   */
  renderInternalLinks = (links) => {
    return links.map((link, i) => (
      <li key={i}>
        <NavLink
          exact
          className="c-header__link u-text-uppercase"
          activeClassName="active"
          to={`${link.route}`}
          onClick={this.handleMobileMenuClose}
        >
          {link.name}
        </NavLink>
      </li>
    ));
  };

  toggleMobileMenuOpen = (e) => {
    e.preventDefault();
    this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen });
  };

  render() {
    const { isMobileMenuOpen } = this.state;
    return (
      <div className="u-position-relative">
        <div className="u-flex u-text-center u-align-items-center u-justify-content-between">
          <div className="u-flex u-align-items-center u-full-width">
            <Logo width="60px" isDefault className="u-margin-right" />
            <Button to="/" btnType="nav" onClick={this.toggleMobileMenuOpen} />

            <ul
              className={`c-header__nav u-margin-bottom-none ${
                isMobileMenuOpen ? 'active' : ''
              }`}
            >
              {this.renderInternalLinks(this.navInternalLinks)}
              <li className="c-header__nav-button u-text-center">
                {/* <a
                  href="http://varnathrowdown.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="c-btn c-btn--danger u-bg-color-mahogany u-color-white"
                >
                  Varna Throwdown
                </a> */}
                <Button to="/login" btnType="primary">
                  Вход
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
