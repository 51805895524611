import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import api from '../../../../lib/api';
import Loading from '../../../../common/Loading';
import Error from '../../../../common/Error';
import Picture from '../../../../common/Picture';
import Wrapper from '../../common/Wrapper';
import Heading from '../../common/Heading';

import news from '../../../../img/icon-news.svg';
import noImgNews from '../../../../img/landing/no-img-news.jpg';
import noImgNewsWebp from '../../../../img/landing/webp/no-img-news.webp';

class SingleNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      article: {},
      errors: {},
      isLoading: true,
    };
  }

  componentDidMount() {
    api
      .getArticle(this.props.match.params.newsItemId)
      .then((article) => {
        this.setState({
          article,
          isLoading: false,
        });
      })
      .catch((err) => {
        const errors = err.errors;
        this.setState({
          errors,
          isLoading: false,
        });
      });
  }

  render() {
    const { article, isLoading, errors } = this.state;
    return (
      <Wrapper headerSecondary>
        <Helmet>
          <title>{article.title}</title>
          <meta name="description" content={article.title} />
        </Helmet>
        <div className="o-wrapper o-wrapper--large u-margin-bottom-huge">
          {isLoading && <Loading />}
          {!isLoading && (
            <>
              <Heading
                headingSecondary
                title={article.title}
                src={news}
                className="u-margin-bottom-large"
              />
              <Error message={errors} />
              {!Object.keys(errors).length && (
                <>
                  <Picture
                    src={article.image.url || noImgNews}
                    srcWebp={article.image.url ? null : noImgNewsWebp}
                    className="u-border-radius u-img-responsive u-object-fit u-margin-bottom"
                    alt="Article Img"
                    width="100%"
                    height="300px"
                  />
                  <p className="u-text-display-space">{article.description}</p>
                </>
              )}
            </>
          )}
        </div>
      </Wrapper>
    );
  }
}

export default SingleNews;
