import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../../common/Button';
import Picture from '../../../common/Picture';
import noImgNews from '../../../img/landing/no-img-news.jpg';
import noImgNewsWebp from '../../../img/landing/webp/no-img-news.webp';

const Item = ({ article }) => (
  <div>
    <div className="o-layout u-margin-bottom-large">
      <div className="o-layout__item u-1/1 u-1/3@lg">
        <Link to={`/news/${article.id}`}>
          <Picture
            src={article.image.url || noImgNews}
            srcWebp={article.image.url ? null : noImgNewsWebp}
            className="u-img-responsive u-object-fit u-margin-bottom"
            alt="Article Img"
            width="310px"
            height="235px"
          />
        </Link>
      </div>
      <div className="c-box__content o-layout__item u-1/1 u-2/3@lg u-text-right">
        <h3 className="u-margin-bottom-small u-text-word-break">
          {article.title}
        </h3>
        <p className="u-text-display-space c-box__content--truncate">
          {article.description}
        </p>
        <Button
          to={`/news/${article.id}`}
          btnType="primary"
          className="c-btn--small u-margin-top"
        >
          виж повече
        </Button>
      </div>
    </div>
  </div>
);

export default Item;
