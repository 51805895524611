import React, { useState } from 'react';

import api from '../../../lib/api';
import TextInput from '../../../common/TextInput';
import InputFeedback from '../../../common/InputFeedback';
import Logo from '../../../common/Logo';

const ActivateUser = ({ match }) => {
  const [errors, setErrors] = useState({});
  const [inputs, setInputs] = useState({
    password: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const submit = (e) => {
    e.preventDefault();
    const { password, confirmPassword } = inputs;
    if (password !== confirmPassword) {
      return setErrors({
        confirmPassword: 'Паролите не съвпадат',
      });
    }

    api
      .activateUser(match.params.code, password)
      .then(({ role }) => {
        // automatically log in user and reroute to proper dashboard
        localStorage.setItem('role', role);
        window.location = `/${role}`;
      })
      .catch((err) => {
        setErrors(err.errors);
      });
  };

  return (
    <div className="o-flx-cell-grow o-flx o-flx--center o-flx--justify c-header">
      <form className="o-flx-col o-flx--center c-form-holder" onSubmit={submit}>
        <Logo
          width="100px"
          isDefault
          className="u-margin-bottom u-padding-small"
        />
        <h2 className="u-text-uppercase u-color-white">Активиране на акаунт</h2>
        <div className="o-flx-col o-flx--center u-margin-bottom-tiny">
          <TextInput
            name="password"
            value={inputs.password}
            onChange={handleChange}
            placeholder="Въведи парола"
            error={errors.password}
            type="password"
          />
          <TextInput
            name="confirmPassword"
            value={inputs.confirmPassword}
            onChange={handleChange}
            placeholder="Потвърди въведената парола"
            error={errors.confirmPassword}
            type="password"
          />
          <InputFeedback message={errors.base} />
        </div>
        <button type="submit" className="c-btn c-btn--primary">
          активирай
        </button>
      </form>
    </div>
  );
};

export default ActivateUser;
