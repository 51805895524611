import React from 'react';
import cfLogo from '../../../img/landing/cf-logo.svg';

const CfLogo = ({ width, height, className }) => (
  <a href="https://www.crossfit.com" target="_blank" rel="noopener noreferrer">
    <img
      src={cfLogo}
      width={width}
      height={height}
      alt="CrossFit Logo"
      className={className}
    />
  </a>
);

export default CfLogo;
