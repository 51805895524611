import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import api from '../../../../lib/api';
import isUserLoggedIn from '../../../../lib/isUserLoggedIn';
import withRecaptcha from '../../common/withRecaptcha';
import Wrapper from '../../common/Wrapper';
import Heading from '../../common/Heading';
import Loading from '../../../../common/Loading';
import Error from '../../../../common/Error';
import Picture from '../../../../common/Picture';
import Comment from './Comment';

import Wod from '../../../../models/Wod';

import kettlebell from '../../../../img/icon-kettlebell.svg';
import chat from '../../../../img/icon-chat.svg';
import noImg from '../../../../img/landing/no-img.jpg';
import noImgWebp from '../../../../img/landing/webp/no-img.webp';

class SingleWod extends Component {
  state = {
    authorName: '',
    commentText: '',
    wod: new Wod({}),
    comments: [],
    wodErrors: {},
    wodLoading: true,
    commentsErrors: {},
    commentsLoading: true,
  };

  isLoggedUser = isUserLoggedIn();

  async componentDidMount() {
    const { wodId } = this.props.match.params;
    // fetch wod
    try {
      const wod = await api.getWod(wodId);
      this.setState({
        wod,
        wodLoading: false,
      });
    } catch (err) {
      const errors = err.errors;
      this.setState({
        wodErrors: errors,
        wodLoading: false,
      });
    }

    // fetch wod comments
    try {
      const comments = await api.getWodComments(wodId);
      this.setState({
        comments,
        commentsLoading: false,
      });
    } catch (err) {
      const errors = err.errors;
      this.setState({
        commentsErrors: errors,
        commentsLoading: false,
      });
    }
  }

  onCommentChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  createComment = async ({ wodId, parentId = null, authorName, text }) => {
    // generate recaptcha
    let recaptcha;
    try {
      recaptcha = await this.props.getRecaptcha();
    } catch (err) {
      // exact error is not available here
      const error = new Error('ValidationError');
      error.errors = { base: 'Грешка в recaptcha' };
      throw error;
    }

    // create comment
    let createCommentApiCall = () =>
      api.createComment(
        {
          wodId,
          parentId,
          authorName,
          text,
        },
        recaptcha
      );

    if (this.isLoggedUser) {
      createCommentApiCall = () =>
        api.createCommentFromAuthenticatedUser(
          {
            wodId,
            parentId,
            text,
          },
          recaptcha
        );
    }

    const comment = await createCommentApiCall();

    return comment;
  };

  onCommentSubmit = async (e) => {
    e.preventDefault();
    const { authorName, commentText } = this.state;
    this.setState({ commentsLoading: true });

    try {
      const comment = await this.createComment({
        wodId: this.props.match.params.wodId,
        authorName,
        text: commentText,
      });

      this.setState({
        authorName: '',
        commentText: '',
        comments: [...this.state.comments, comment],
        commentsLoading: false,
      });
    } catch (err) {
      this.setState({
        commentsErrors: err.errors,
        commentsLoading: false,
      });
    }
  };

  onSubcommentSubmit = async ({ parentId, authorName, text }) => {
    this.setState({ commentsLoading: true });

    try {
      const comment = await this.createComment({
        wodId: this.props.match.params.wodId,
        parentId,
        authorName,
        text,
      });

      const updatedCommentIndex = this.state.comments.findIndex(
        (currentComment) => currentComment.id === comment.id
      );
      const comments = this.state.comments.map((item, index) => {
        if (index !== updatedCommentIndex) return item;
        return comment;
      });
      this.setState({
        comments,
        commentsLoading: false,
      });
    } catch (err) {
      this.setState({
        commentsErrors: err.errors,
        commentsLoading: false,
      });
    }
  };

  render() {
    const {
      wod,
      comments,
      wodErrors,
      wodLoading,
      commentsErrors,
      commentsLoading,
    } = this.state;

    const workoutsFlattened = wod.workouts.reduce((flattened, workout) => {
      if (workout.title)
        flattened.push(<h4 key={`${workout.id}-title`}>{workout.title}</h4>);
      if (workout.subtitles.length) {
        workout.subtitles.forEach((subtitle, idx) => {
          flattened.push(
            <p
              key={`${workout.id}-subtitle-${idx}`}
              className="u-font-italic u-margin-vertical-small"
            >
              {subtitle}
            </p>
          );
        });
      }

      return [
        ...flattened,
        ...workout.exercises.map((exercise, idx) => (
          <p
            key={`${workout.id}-exercise-${idx}`}
            className="u-padding-left-small"
          >
            <span className="u-padding-right-small">&ndash;</span>
            {exercise}
          </p>
        )),
        <p
          key={`${workout.id}-transition`}
          className="u-border-left u-padding-left u-text-display-space u-text-left u-margin-top u-margin-bottom u-weight-light u-font-italic"
        >
          {workout.transition}
        </p>,
      ];
    }, []);

    const workoutsDescriptionFlattened = wod.workouts.reduce(
      (flattened, workout) => {
        const { id, scaling, description } = workout;

        if (description) {
          flattened.push(
            <p
              key={`${id}-description`}
              className="u-text-display-space u-text-left u-margin-bottom u-weight-light u-font-italic"
            >
              {description}
            </p>
          );
        }

        return [
          ...flattened,
          ...(scaling || []).map((scaling, idx) => {
            return (
              <div
                key={`${id}-scaling-${idx}`}
                className="u-text-left u-margin-top"
              >
                <h6 className="u-margin-bottom-tiny">{scaling.title}</h6>
                {(scaling.exercises || []).map((exercise, exerciseIdx) => (
                  <p key={`${id}-scaling-${idx}-exercise-${exerciseIdx}`}>
                    {exercise}
                  </p>
                ))}
              </div>
            );
          }),
        ];
      },
      []
    );

    return (
      <Wrapper headerSecondary>
        <Helmet>
          <title>WOD | {wod.date ? wod.date.format('DD.MM.YYYY') : ''}</title>
          <meta name="description" content="WOD" />
        </Helmet>
        <Error message={wodErrors} />
        {wodLoading && <Loading />}
        {!wodLoading && !Object.keys(wodErrors).length && (
          <div className="o-wrapper o-wrapper--large u-margin-bottom">
            <Heading
              headingSecondary
              title={`WOD ${wod.date ? wod.date.format('DD.MM.YYYY') : ''} | ${
                wod.name
              }`}
              src={kettlebell}
              className="u-margin-bottom-large"
            />
            <Picture
              src={wod.image.url ? wod.image.url : noImg}
              srcWebp={wod.image.url ? null : noImgWebp}
              className="u-border-radius u-img-responsive u-object-fit u-margin-bottom"
              alt="Workout of the Day Img"
              width="100%"
              height="450px"
            />
            <div className="o-layout__item u-1/1 u-1/2@md u-padding-large c-box__content u-margin-vertical-small u-text-left">
              {workoutsFlattened}
            </div>
            {!!workoutsDescriptionFlattened.length && (
              <div className="o-layout__item u-1/1 u-1/2@md u-padding-large c-box__content u-bg-color-solitude u-border-radius u-margin-vertical-small u-text-center">
                <h3 className="u-text-uppercase u-color-royal-blue u-text-left u-margin-bottom-small">
                  <span className="u-margin-right-small">&mdash;</span>
                  Съвети и скалиране
                </h3>
                {workoutsDescriptionFlattened}
              </div>
            )}
          </div>
        )}
        {!Object.keys(wodErrors).length && (
          <>
            <div className="u-polygon-bg-up u-bg-color-solitude">
              <div className="o-wrapper o-wrapper--large">
                <Heading
                  title="коментари"
                  subtitle="сподели мнение"
                  src={chat}
                  className="u-justify-content-center u-margin-bottom-large"
                />
                {!commentsLoading &&
                  comments.map((comment) => {
                    const key = `${comment.id}-${comment.comments
                      .map((c) => c.id)
                      .join('-')}`;
                    return (
                      <Comment
                        key={key}
                        comment={comment}
                        onSubcommentSubmit={this.onSubcommentSubmit}
                      />
                    );
                  })}
                {commentsErrors && (
                  <Error
                    message={
                      commentsErrors.base ||
                      commentsErrors.recaptcha ||
                      commentsErrors.comment
                    }
                  />
                )}
                {commentsLoading && <Loading />}
                <h2 className="u-text-uppercase u-margin-top-huge">
                  Остави коментар
                </h2>
                <form onSubmit={this.onCommentSubmit}>
                  <div className="u-margin-bottom">
                    {!this.isLoggedUser && (
                      <input
                        className="c-input u-margin-bottom"
                        placeholder="Име"
                        name="authorName"
                        value={this.state.authorName}
                        onChange={this.onCommentChange}
                      />
                    )}
                    <textarea
                      className="c-input c-textarea c-textarea--large"
                      type="text"
                      placeholder="Коментар..."
                      name="commentText"
                      value={this.state.commentText}
                      onChange={this.onCommentChange}
                    />
                  </div>
                  <button type="submit" className="c-btn c-btn--primary">
                    изпрати коментар
                  </button>
                </form>
              </div>
            </div>
          </>
        )}
      </Wrapper>
    );
  }
}

export default withRecaptcha(SingleWod);
